import { Injectable } from '@angular/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const menuLinks: any[] = [
  {
    path: '/home/order',
    display: 'Request Order',
  },
  {
    path: '/home/price-checker',
    display: 'Price Checker',
  },
  {
    path: '/home/disposal',
    display: 'Item Disposal',
  },
  {
    path: '/home/stock-take-edit',
    display: 'Stock Take',
  },
  ...(environment.name !== 'production'
    ? [
        {
          path: '/home/stock',
          display: 'Items',
        },
        {
          path: '/home/debtor',
          display: 'Debtors',
        },
        {
          path: '/home/debtor-aging-list',
          display: 'Debtor Agings',
        },
      ]
    : []),
  {
    path: '/home/cross-check',
    display: 'Cross Check',
  },
];

@Injectable({
  providedIn: 'root',
})
export class AppService {
  readonly environment: string;

  readonly iframeUrl: string;
  readonly apiUrl: string;
  readonly socketIoUrl: string;

  localStorageStatus: 'Pending' | 'Running' | 'Done' = 'Pending';
  private localStorageStatusStream: Subject<any> = new Subject();

  cartItems: any[] = [];
  cartBadgeStream: BehaviorSubject<number> = new BehaviorSubject(0);

  companyName: string;

  launchBarcodeScanner: Subject<any> = new Subject();
  barcodeScannerResult: Subject<any> = new Subject();
  launchSocialSharing: Subject<any> = new Subject();
  socialSharingResult: Subject<any> = new Subject();
  localStorageSetItem: Subject<any> = new Subject();
  localStorageSetItemResult: Subject<any> = new Subject();
  localStorageGetItem: Subject<any> = new Subject();
  localStorageGetItemResult: Subject<any> = new Subject();
  localStorageRemoveItem: Subject<any> = new Subject();
  localStorageRemoveItemResult: Subject<any> = new Subject();
  localStorageClear: Subject<any> = new Subject();
  localStorageClearResult: Subject<any> = new Subject();
  localStorageGetAllKeys: Subject<any> = new Subject();
  localStorageGetAllKeysResult: Subject<any> = new Subject();

  constructor(
    private alertController: AlertController,
    private barcodeScanner: BarcodeScanner,
    private socialSharing: SocialSharing,
  ) {
    this.iframeUrl = environment.iframeUrl;
    this.apiUrl = environment.apiUrl;
    this.socketIoUrl = environment.socketIoUrl;

    this.companyName = window.localStorage.getItem('companyName');

    this.initMessageListener();
  }

  initMessageListener() {
    window.onmessage = async (event) => {
      // If listened to self sent messages then ignore
      if (event.origin === location.origin) {
        return;
      }

      let json = null;
      try {
        json = JSON.parse(event.data);
      } catch (err) {}

      if (!json) {
        const alert = await this.alertController.create({
          backdropDismiss: false,
          header: 'Error',
          message: 'An error has occurred. Invalid message.',
          buttons: ['OK'],
        });

        await alert.present();
        return;
      }

      switch (json.name) {
        // Parent listen will store the item in local storage
        case 'localStorageSetItem': {
          localStorage.setItem(json.data.key, json.data.value);

          this.localStorageSetItem.next({
            name: 'localStorageSetItemResult',
            data: null,
          });
          break;
        }
        // Parent listen will get the item from local storage
        case 'localStorageGetItem': {
          const value = localStorage.getItem(json.data.key);

          this.localStorageGetItem.next({
            name: 'localStorageGetItemResult',
            data: value,
          });
          break;
        }
        // Parent listen will remove the item from local storage
        case 'localStorageRemoveItem': {
          localStorage.removeItem(json.data.key);

          this.localStorageRemoveItem.next({
            name: 'localStorageRemoveItemResult',
            data: null,
          });
          break;
        }
        // Parent listen will clear local storage
        case 'localStorageClear': {
          localStorage.clear();

          this.localStorageClear.next({
            name: 'localStorageClearResult',
            data: null,
          });
          break;
        }
        // Parent listen will get all the keys in local storage
        case 'localStorageGetAllKeys': {
          const allKeys = Object.keys(localStorage);

          this.localStorageGetAllKeys.next({
            name: 'localStorageGetAllKeysResult',
            data: allKeys,
          });
          break;
        }
        // iframe listen will publish the result
        case 'localStorageSetItemResult': {
          this.localStorageSetItemResult.next(json.data);
          break;
        }
        // iframe listen will publish the result
        case 'localStorageGetItemResult': {
          this.localStorageGetItemResult.next(json.data);
          break;
        }
        // iframe listen will publish the result
        case 'localStorageRemoveItemResult': {
          this.localStorageRemoveItemResult.next(json.data);
          break;
        }
        // iframe listen will publish the result
        case 'localStorageClearResult': {
          this.localStorageClearResult.next(json.data);
          break;
        }
        // iframe listen will publish the result
        case 'localStorageGetAllKeysResult': {
          this.localStorageGetAllKeysResult.next(json.data);
          break;
        }
        // Parent listen will launch barcode scanner
        case 'launchBarcodeScanner': {
          try {
            const barcodeData = await this.barcodeScanner.scan();

            this.launchBarcodeScanner.next({
              name: 'barcodeScannerResult',
              data: barcodeData,
            });
          } catch (err) {
            const alert = await this.alertController.create({
              backdropDismiss: false,
              header: 'Error',
              message:
                'An error has occurred. Unable to launch barcode scanner.',
              buttons: ['OK'],
            });

            await alert.present();
          }
          break;
        }
        // Parent listen will launch social sharing
        case 'launchSocialSharing': {
          try {
            this.launchSocialSharing.next({
              name: 'socialSharingResult',
              data: null,
            });

            await this.socialSharing.shareWithOptions(json.data);
          } catch (err) {
            const alert = await this.alertController.create({
              backdropDismiss: false,
              header: 'Error',
              message:
                'An error has occurred. Unable to launch social sharing.',
              buttons: ['OK'],
            });

            await alert.present();
          }
          break;
        }
        // iframe listen will publish the result
        case 'barcodeScannerResult': {
          this.barcodeScannerResult.next(json.data);
          break;
        }
        // iframe listen will publish the result
        case 'socialSharingResult': {
          this.socialSharingResult.next(json.data);
          break;
        }
        default: {
          const alert = await this.alertController.create({
            backdropDismiss: false,
            header: 'Error',
            message: 'An error has occurred. Unknown action.',
            buttons: ['OK'],
          });

          await alert.present();
          break;
        }
      }
    };
  }

  async initLocalStorage() {
    if (this.localStorageStatus === 'Done') {
      return;
    }

    if (this.localStorageStatus === 'Running') {
      return new Promise<void>((resolve, reject) => {
        const sub = this.localStorageStatusStream
          .pipe(first())
          .subscribe((data) => {
            sub.unsubscribe();

            if (data === 'Done') {
              return resolve();
            }

            return reject();
          });
      });
    }

    this.localStorageStatus = 'Running';

    // [2021-11-08 @ Dino] On app start we try to copy all local storage values from parent if any
    try {
      const user = await this.localStoragegetItem('user');
      const token = await this.localStoragegetItem('token');
      const companyName = await this.localStoragegetItem('companyName');
      const selectedLocationId = await this.localStoragegetItem(
        'selectedLocationId',
      );
      const sessionLocationId = await this.localStoragegetItem(
        'sessionLocationId',
      );

      if (user !== null) {
        localStorage.setItem('user', user);
      }
      if (token !== null) {
        localStorage.setItem('token', token);
      }
      if (companyName !== null) {
        localStorage.setItem('companyName', companyName);
      }
      if (selectedLocationId !== null) {
        localStorage.setItem('selectedLocationId', selectedLocationId);
      }
      if (sessionLocationId !== null) {
        localStorage.setItem('sessionLocationId', sessionLocationId);
      }

      // Get all the keys out
      const keys = await this.localStoragegetAllKeys();

      // For every key we check is it a sales order
      for (const key of keys) {
        // If not a sales order then skip
        if (key.indexOf('SO-') !== 0) {
          continue;
        }

        // Get the sales order
        const value = await this.localStoragegetItem(key);

        // Set to self local storage
        localStorage.setItem(key, value);
      }
    } catch (err) {
      // Do nothing for now
    }

    this.localStorageStatus = 'Done';
    this.localStorageStatusStream.next(this.localStorageStatus);
  }

  initCart() {
    // Get all the keys
    const keys = Object.keys(localStorage);

    // For every key we check is it a sales order
    for (const key of keys) {
      // If not a sales order then skip
      if (key.indexOf('SO-') !== 0) {
        continue;
      }

      // Get the sales order
      const value = localStorage.getItem(key);

      let parsed = null;
      try {
        parsed = JSON.parse(value);
      } catch (err) {
        // TODO: Let the user know some error has occurred
        break;
      }

      // Add to the cart
      this.cartItems.push(parsed);

      // Set the badge and notify
      this.cartBadgeStream.next(this.cartItems.reduce((p, c) => p + c.qty, 0));
    }
  }

  async localStoragesetItem(key: string, value: string) {
    return new Promise<void>((resolve, reject) => {
      let sub: Subscription = null;

      const timeoutId = setTimeout(() => {
        sub?.unsubscribe();
        sub = null;

        reject();
      }, 25);

      sub = this.localStorageSetItemResult.pipe(first()).subscribe(() => {
        clearTimeout(timeoutId);

        sub?.unsubscribe();
        sub = null;

        resolve();
      });

      window.top.postMessage(
        JSON.stringify({
          name: 'localStorageSetItem',
          data: {
            key: key,
            value: value,
          },
        }),
        '*',
      );
    });
  }

  async localStoragegetItem(key: string) {
    return new Promise<string>((resolve, reject) => {
      let sub: Subscription = null;

      const timeoutId = setTimeout(() => {
        sub?.unsubscribe();
        sub = null;

        reject();
      }, 25);

      sub = this.localStorageGetItemResult.pipe(first()).subscribe((data) => {
        clearTimeout(timeoutId);

        sub?.unsubscribe();
        sub = null;

        resolve(data);
      });

      window.top.postMessage(
        JSON.stringify({
          name: 'localStorageGetItem',
          data: {
            key: key,
          },
        }),
        '*',
      );
    });
  }

  async localStorageremoveItem(key: string) {
    return new Promise<void>((resolve, reject) => {
      let sub: Subscription = null;

      const timeoutId = setTimeout(() => {
        sub?.unsubscribe();
        sub = null;

        reject();
      }, 25);

      sub = this.localStorageRemoveItemResult.pipe(first()).subscribe(() => {
        clearTimeout(timeoutId);

        sub?.unsubscribe();
        sub = null;

        resolve();
      });

      window.top.postMessage(
        JSON.stringify({
          name: 'localStorageRemoveItem',
          data: {
            key: key,
          },
        }),
        '*',
      );
    });
  }

  async localStorageclear() {
    return new Promise<void>((resolve, reject) => {
      let sub: Subscription = null;

      const timeoutId = setTimeout(() => {
        sub?.unsubscribe();
        sub = null;

        reject();
      }, 25);

      sub = this.localStorageClearResult.pipe(first()).subscribe(() => {
        clearTimeout(timeoutId);

        sub?.unsubscribe();
        sub = null;

        resolve();
      });

      window.top.postMessage(
        JSON.stringify({
          name: 'localStorageClear',
        }),
        '*',
      );
    });
  }

  async localStoragegetAllKeys() {
    return new Promise<string[]>((resolve, reject) => {
      let sub: Subscription = null;

      const timeoutId = setTimeout(() => {
        sub?.unsubscribe();
        sub = null;

        reject();
      }, 25);

      sub = this.localStorageGetAllKeysResult
        .pipe(first())
        .subscribe((data) => {
          clearTimeout(timeoutId);

          sub?.unsubscribe();
          sub = null;

          resolve(data);
        });

      window.top.postMessage(
        JSON.stringify({
          name: 'localStorageGetAllKeys',
        }),
        '*',
      );
    });
  }

  // This function here will add the stock item into the storage (cart) and then notify to update the badge
  async addToCart(stockItem: any) {
    // Construct the unique key
    const key = `SO-${stockItem.itemCode}-${stockItem.uom}`;

    // Try and check whether already have this value in storage
    let value: any = localStorage.getItem(key);

    // If already have this value we increase the quantity
    if (value !== null) {
      let parsed = null;

      try {
        parsed = JSON.parse(value);

        ++parsed.qty;
      } catch (err) {
        // TODO: Let the user know some error has occurred
        return;
      }

      // Stringify the value back
      value = JSON.stringify(parsed);

      // Store it back to the storage
      localStorage.setItem(key, value);
      await this.localStoragesetItem(key, value).catch((err) => {});

      // Set the badge and notify
      const item = this.cartItems.filter(
        (v) => v.itemCode === stockItem.itemCode && v.uom === stockItem.uom,
      )[0];
      ++item.qty;
      this.cartBadgeStream.next(this.cartItems.reduce((p, c) => p + c.qty, 0));
      return;
    }

    const parsed = {
      itemCode: stockItem.itemCode,
      uom: stockItem.uom,
      qty: 1,
      description: stockItem.description,
      price: stockItem.price,
    };

    value = JSON.stringify(parsed);

    // Add to cart (localStorage) use itemCode + uom to get a unique identifier
    localStorage.setItem(key, value);
    await this.localStoragesetItem(key, value).catch((err) => {});

    // Set the badge and notify
    this.cartItems.push(parsed);
    this.cartBadgeStream.next(this.cartItems.reduce((p, c) => p + c.qty, 0));
  }

  // This function here will remove the stock item from the storage (cart) and then notify to update the badge
  async removeFromCart(stockItem: any) {
    // Construct the unique key
    const key = `SO-${stockItem.itemCode}-${stockItem.uom}`;

    // Try and check whether already have this value in storage
    let value: any = localStorage.getItem(key);

    // If don't have this value we cannot do anything
    if (value === null) {
      return;
    }

    // Remove it from the storage
    localStorage.removeItem(key);
    await this.localStorageremoveItem(key).catch((err) => {});

    // Set the badge and notify
    const item = this.cartItems.findIndex(
      (v) => v.itemCode === stockItem.itemCode && v.uom === stockItem.uom,
    );
    this.cartItems.splice(item, 1);
    this.cartBadgeStream.next(this.cartItems.reduce((p, c) => p + c.qty, 0));
  }

  async clearCart() {
    // Get all the keys out
    const keys = Object.keys(localStorage);

    // For every key we check is it a sales order
    for (const key of keys) {
      // If not a sales order then skip
      if (key.indexOf('SO-') !== 0) {
        continue;
      }

      // Remove it from storage
      localStorage.removeItem(key);
      await this.localStorageremoveItem(key).catch((err) => {});
    }

    // Set the badge and notify
    this.cartItems = [];
    this.cartBadgeStream.next(this.cartItems.reduce((p, c) => p + c.qty, 0));
  }
}
