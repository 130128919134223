import { Component } from '@angular/core';

import { NavController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SocketIoService } from './socket-io.service';
import { AccountService } from './account.service';
import { Router } from '@angular/router';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private accountService: AccountService,
    private toastController: ToastController,
    private navController: NavController,
    private appService: AppService,
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();

      // Only check if is ionic
      if (location.origin.indexOf('localhost') !== -1 && location.port === '') {
        try {
          // Add here before hide splash screen
          // [2022-01-06 @ Dino] No more code push

        } catch (err) {
          // Do nothing for now
        }

        // Wait 1 second delay in case the code push need refresh the page
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Ionic always go to config to display iframe
        await this.navController.navigateRoot(['/config', ''], {
          animationDirection: 'forward',
        });

        this.splashScreen.hide();
        return;
      }

      // If is not ionic then we init the local storage first
      await this.appService.initLocalStorage();

      // Add a slight delay for wait the local storage
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Init the cart
      this.appService.initCart();

      // Check whether user is logged in or not
      const loggedIn = this.accountService.checkLoggedIn();

      // If is logged in then we redirect him to request order
      if (loggedIn) {
        this.appService.companyName = localStorage.getItem('companyName');

        await this.navController.navigateRoot(['/dashboard'], {
          animationDirection: 'forward',
        });
        return;
      }

      // If not yet logged in then we check whether previously got set realm or not
      const companyName = localStorage.getItem('companyName');

      // If previously already set realm then we go to login
      if (companyName) {
        await this.navController.navigateForward(['/login', companyName]);
        return;
      }

      // If not yet set realm then we go to config
      await this.navController.navigateForward(['/config', '']);
    });
  }
}
