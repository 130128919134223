import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private user: any;
  private token: string;

  constructor(
    private appService: AppService,
    private httpClient: HttpClient,
    private navController: NavController,
  ) {}

  getFirstRoleName() {
    return this.user?.roles[0]?.roleName;
  }

  getUserId() {
    return this.user?.id;
  }

  getUsername() {
    return this.user?.username;
  }

  getToken() {
    return this.token;
  }

  checkLoggedIn() {
    // Check if has token (logged in before, if has then redirect)
    const token = window.localStorage.getItem('token');

    if (token) {
      this.token = token;

      const userString = window.localStorage.getItem('user');

      try {
        this.user = JSON.parse(userString);
      } catch (err) {
        return false;
      }

      return true;
    }

    return false;
  }

  login(
    companyName: string,
    username: string,
    password: string,
  ): Observable<any> {
    const trimmedUsername = username.trim();

    return this.httpClient
      .post(`${this.appService.apiUrl}/api/account/login`, {
        companyName: companyName,
        data: {
          username: trimmedUsername,
          password: password,
        },
      })
      .pipe(
        tap(async (res) => {
          if (!res.success) {
            return;
          }

          this.user = res.data;
          this.token = res.token;

          window.localStorage.setItem('user', JSON.stringify(this.user));
          window.localStorage.setItem('token', res.token);

          // [2021-11-08 @ Dino] We also keep one copy in the parent if any
          await this.appService
            .localStoragesetItem('user', JSON.stringify(this.user))
            .catch((err) => {});
          await this.appService
            .localStoragesetItem('token', res.token)
            .catch((err) => {});
        }),
      );
  }

  async logout() {
    window.localStorage.removeItem('sessionLocationId');
    window.localStorage.removeItem('selectedLocationId');

    window.localStorage.removeItem('user');
    window.localStorage.removeItem('token');

    // [2021-11-08 @ Dino] We remove the copy in the parent if any
    await this.appService
      .localStorageremoveItem('sessionLocationId')
      .catch((err) => {});
    await this.appService
      .localStorageremoveItem('selectedLocationId')
      .catch((err) => {});

    await this.appService.localStorageremoveItem('user').catch((err) => {});
    await this.appService.localStorageremoveItem('token').catch((err) => {});

    this.navController.navigateRoot(['/login', this.appService.companyName], {
      animationDirection: 'back',
    });
  }
}
