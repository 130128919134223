import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { PromptCrossCheckComponent } from './prompt-cross-check.component';

@NgModule({
  declarations: [PromptCrossCheckComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  exports: [PromptCrossCheckComponent],
})
export class PromptCrossCheckModule {}
